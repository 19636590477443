@use '../abstracts' as *;

$bs-primary: map-get($map: $theme, $key: "secondary");
$bs-secondary: map-get($map: $theme, $key: "primary");
$bs-warning: map-get($map: $theme, $key: "warning");
$bs-danger: map-get($map: $theme, $key: "danger");
$bs-success: map-get($map: $theme, $key: "success");

$theme-colors: (
  "primary": $bs-primary,
  "secondary": $bs-secondary,
  "warning": $bs-warning,
  "danger": $bs-danger,
  "success": $bs-success
);

$form-label-font-size: $txt-14;

@import "~bootstrap/scss/bootstrap";

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      max-width: 1140px;
  }
}