@use '../abstracts' as *;

.layout{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.overflow-toggle,
body.overflow-toggle {
  overflow-x: auto;
}

.c-code {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--cl-text-dark);
  padding: 12px 10px;
}

.middle {
  @include absCenter;
}

:is(.flex-center, .flex-column, .flex-row, .d-flex).gap {
  gap: 1.1ch;
}
:is(.flex-center, .flex-column, .flex-row, .d-flex).gap-2ch {
  gap: 2.1ch;
}
:is(.flex-center, .flex-column, .flex-row, .d-flex).gap-3ch {
  gap: 3.2ch;
}

.flex-row {
  @include flex-row;
}

.mb-6 {
  margin-bottom: 5rem;
}

.flex-center {
  @include flex-center;
}

.flex-column {
  @include flex-column;
}

.width-change {
  width: 80%;
  @include mq($bp-lg) {
    width: 45%;
  }
}

.white-curve {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
}

.bg-primary-light {
  background: var(--cl-primary-light);
}

.bg-yellow {
  background: var(--cl-primary) !important;
}

.bg-warning {
  background: var(--cl-warning) !important;
}

.border-primary {
  border: 2px solid var(--cl-primary) !important;
}

.padded-intro-section {
  padding: 100px 0 200px;
  @include mq($bp-md-2, 'max') {
    padding: 60px 0 150px;
  }
}
.fw-600 {
  font-weight: 600 !important;
}

.intro-content {
  width: min(850px, 100%);
  margin: 0 auto;
}

.negative-margin-card {
  margin-top: -120px;
  @include mq($bp-md-2, 'max') {
    margin-top: -65px;
  }
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.light-yellow-curve-bg {
  background: url('/images/light-yellow-curve-bg.svg');
  background-repeat: no-repeat;
  background-position: 0% 50%;
}

.darken {
  mix-blend-mode: darken;
}


.light-yellow-icon {
  width: 65px;
  height: 65px;
  background: var(--cl-primary-light);
  border: 2px solid var(--cl-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

.colored-line {
  height: 1px;
  width: 70px;
  background: var(--cl-secondary);
}
.line-center {
  margin: 10px auto;
}