@use '../abstracts' as *;

/************* Content loader ****************/
.content-loader {
	min-height: 10px;
	min-width: 20px;
	background-color: hsl(0 0% 65% / 50%) !important;
	will-change: opacity;
	border-radius: 5px;
	z-index: 1;
	animation: loader 3s infinite ease-in-out;
}

/************* Site Loader ***************/ 
.loader {
	z-index: 99;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: var(--cl-background);
	display: grid;
	place-content: center;
	perspective: 1200px;
}

.loader-inner {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	background: var(--cl-primary);
	display: flex;
	align-items: center;
	justify-content: center;
}

.front, .back, .page1, .page2, .page3, .page4, .page5 {
	transform-style: preserve-3d;
	position: absolute;
	width: 55px;
	height: 100%;
	top: 0; left: 0;
	transform-origin: left center;
	transition: transform .5s ease-in-out, box-shadow .35s ease-in-out;
}

.front, .back {
	background: var(--cl-grey-dark);
}

.front, .page1, .page3 {
	border-bottom-right-radius: rem(8);
	border-top-right-radius: rem(8);
}

.back, .page2, .page4, .page5 {
	border-bottom-right-radius: rem(8);
	border-top-right-radius: rem(8);
}

.page1, .page2 {
	background: hsl(0, 0%, 94%);
}

.page3, .page4 {
	background: hsl(0, 0%, 96%);
}
.page5 {
	background: hsl(0, 0%, 99%);
}

%box-shadow {
	box-shadow: 0 1rem 3rem 0 $cblack-trans-2;
}

.book { 
	transform-style: preserve-3d;
	position: relative;
	height: 75px;
	cursor: pointer;
	backface-visibility: visible;
	-webkit-backface-visibility: visible;
	.front {
		transform: rotateY(-160deg);
	}
	.page1, .page2, .page3, .page4, .page5 {
		@media not all and (min-resolution:.001dpcm) { 
			box-shadow: none;
		}
		@extend %box-shadow;
	}
	.page1 {
		transform: rotateY(-150deg);
	}
	.page2 {
		transform: rotateY(-30deg);
	}
	.page3 {
		transform: rotateY(-140deg);
	}
	.page4 {
		transform: rotateY(-40deg);
	}
	.page5 {
		animation: turnPage 0.6s ease infinite;
		transform: rotateY(-50deg);
	}
	.back {
		transform: rotateY(-20deg);
	}
}