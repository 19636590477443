@use '../abstracts' as *;

/* Navigation
***************************************************/

.nav-margin {
  margin-top: 70px;
}

.nav-margin.ak {
  margin-top: 98px;
  @include mq($bp-sm-3, 'max') {
    margin-top: 70px;
  }
}


header.top-header {
  padding: 0 20px;
  isolation: isolate;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 70px;
  transition: all 0.3s ease;
  background-color: var(--cl-background);
  border-bottom: 1px solid var(--cl-primary);
  box-shadow: 0px 1px 10px $cblack-trans-2;
  @include mq($bp-sm, 'max') {
    padding: 0 .85rem;
  }
}

.nav-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  width: 120px;
  height: auto;
  display: flex;
  align-items: center;
  img {
    margin: auto;
    // height: 85%;
    // width: auto;
  }
  @include mq($bp-sm-2, 'max') {
    width: 90px;
    height: auto;
  }
}


.nav-links {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  list-style: none;
  gap: 1.4rem;
  color: var(--cl-grey-dark);
  li {
    padding: 4px;
    // margin-right: 24px;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    color: inherit;
    &.active {
      color: var(--cl-text-dark);
      border-bottom: 1px solid var(--cl-primary);
    }
    &:hover, &:focus-visible {
      color: var(--cl-text-dark);
    }
  }
  a {
    color: inherit;
  }
  &.nav-right {
    gap: 1ch;
    li {
      padding: 0;
    }
    .nav-btn-resp {
      @include mq($bp-sm, 'max') {
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
      }
    }
  }
}

.mobile-nav-links {
  display: block;
  @include mq($bp-lg-2){
    display: none;
  }
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: auto;
  padding: 90px 0 20px;
  background: var(--cl-background);
  box-shadow: 0px 1px 10px 10px $cblack-trans-2;
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
  .nav-links {
    display: none;
  }
}

.mobile-nav-links.show {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
  .nav-links {
    display: block;
  }
}

.mobile-nav-links .nav-links li {
  padding: .65rem 1.12rem;
  margin: 0 1rem;
  font-family: inherit;
  font-size: $txt-20;
  font-weight: 600;
  text-align: end;
  &.active {
    color: var(--cl-text-dark);
    border-bottom: none;
    font-weight: 700;
  }
}
