@use '../abstracts' as *;

.features {
  margin: 3.2em auto 5em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 3.5rem;
  align-items: flex-start;
  .feature {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
    &-icon {
      width: 72px;
      height: 72px;
    }
    @include mq($bp-md-2, 'max') {
      grid-row-gap: .5rem;
      &-icon {
        margin: 0 auto;
      }
      &-text {
        text-align: center;
      }
    }
  }
  @include mq($bp-xl-2, 'max') {
    margin: 2rem auto;
    gap: 2rem;
  }
}
// .features.essay-act {
//   grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
//   gap: 4rem 3rem;
//   align-items: center;
// }
