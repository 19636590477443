@use '../abstracts' as *;

.card {
  @include card-shadow;

  .card-header.card-header-yellow {
    background: var(--cl-primary);
  }
}

.avatar-card {
  margin: 80px 12px 0;
  .card-body {
    padding-top: 55px;
    p {
      letter-spacing: .4px;
      line-height: 165%;
    }
    h5 {
      font-size: $txt-16;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
  }
}
.avatar {
  width: 92px;
  height: 92px;
  position: absolute;
  left: 50%;
  top: -48px;
  transform: translateX(-50%);
  border-radius: 50%;
  overflow: hidden;
  &.big {
    width: 140px;
    height: 140px;
    top: -85px;
  }
}
@media only screen and (max-width: 1196px) {
	.numbers-grid {
		grid-template-columns: repeat(2, minmax(255px, 1fr)) !important;
	}
}

