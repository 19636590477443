@use '../abstracts' as *;


#contact {
  position: relative;
}

.contact-card {
  margin-block-start: -30px;
  margin-top: -30px;
  @include mq($bp-md-2,'max') {
    margin-block-start: 0;
    margin-top: 0;
  }
  .card-body {
    padding: 1.5rem;
  }
}

.contact-form {
  margin: 0 auto;
  width: min(650px, 92%);
  .form-floating > label {
    left: unset;
  }
}

.contact-intro-section {
  background-image: url('https://ik.imagekit.io/yhoez8no8al/admission/contact-map.png?tr=h-620,f-auto');
  background-size: cover;
  background-position: 0% 40%;
  height: 55vh;
  @include mq($bp-max-2) {
    height: 65vh;
  }
  @include mq($bp-md-2,'max') {
    background-image: url('https://ik.imagekit.io/yhoez8no8al/admission/contact-map.png?tr=h-350,f-auto');
    height: 45vh;
    background-position: 50% 50%;
  }
}

.contact-card .material-icons {
  font-size: 32px;
  vertical-align: text-bottom;
}
