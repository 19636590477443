@use '../abstracts' as *;


.admissions-yellow-icon {
  width: 120px;
  height: 120px;
  background: var(--cl-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: $txt-54;
    color: var(--cl-background);
  }
}


.heading-with-hr {
  position: relative;
  font-weight: 600;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 48px;
    height: 4px;
    background: var(--cl-secondary-light);
    border-radius: 0.5rem;
  }
  &.--primary {
    &::after {
      background-color: var(--cl-primary);
      @include mq($bp-sm-3, 'max') {
        @include absHrCenter;
      }
    }
  }
}

