@use '../abstracts' as *;


#freeConsultationModal .modal-dialog {
  max-width: 840px;
  border-radius: 0.5rem;
  overflow: hidden;
}
.consultation-modal-details {
  width: 320px;
}

#freeConsultationModal, #ak-contact-card {
  .form-control , .form-select {
    border: 1px solid var(--cl-primary);
    &::placeholder {
      font-size: $txt-14;
      color: var(--cl-grey);
    }
    &:focus {
      border-color: var(--cl-primary);
      box-shadow: var(--box-shadow-input);
    }
  }
}

@media screen and (max-width: 768px) {

  #freeConsultationModal .modal-dialog {
      width: 95%;
  }

  .consultation-modal-details {
      width: 100%;
  }

  // .consultation-modal-details img {
  //     width: 140px;
  // }

}