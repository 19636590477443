@use './func' as *;

$theme: (
  "primary": hsl(49, 100%, 50%),
  "primary-light": hsl(48, 100%, 95%),
  "secondary": hsl(211, 100%, 50%),
  "secondary-light": hsl(210, 100%, 95%),
  "secondary-dark": hsl(227, 82%, 45%),
  "warning": hsl(45, 96%, 42%),
  "warning-light": hsl(45, 100%, 96%),
  "danger": hsl(347, 78%, 43%),
  "danger-light": hsl(0, 81%, 92%),
  "success": hsl(134, 61%, 41%),
  "success-light": hsl(123, 100%, 96%),
  "grey": hsl(0, 0%, 68%),
  "grey-dark": hsl(0, 0%, 44%),
  "text-dark": hsl(0, 0%, 20%),
  "background": hsl(0, 0%, 100%),
  "footer": hsl(229, 72%, 6%)
);

:root {
  @each $name, $color in $theme {
    --cl-#{$name}: #{$color};
  }
}

$cblack-trans-2: hsla(0, 0%, 0%, 0.2);
$cblack-trans-4: hsla(0, 0%, 0%, 0.4);

// FONT
$font-main: 'Mulish', sans-serif;


$txt-8: rem(11);
$txt-12: rem(12);
$txt-14: rem(14);
$txt-16: rem(16);
$txt-18: rem(18);
$txt-20: rem(20);
$txt-24: rem(24);
$txt-32: rem(32);
$txt-36: rem(36);
$txt-42: rem(42);
$txt-54: rem(52);
$txt-64: rem(64);