@use '../abstracts' as *;

.menu-btn {
  --focus-cl: hsl(240, 0%, 45%);
  border: 2px solid transparent;
  background-color: transparent;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  gap: 1ch;
  cursor: pointer;
  padding: .25rem .5rem;
  transition: color .2s ease;
  &.active {
    color: var(--cl-text-dark);
    border: 2px dashed currentColor;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:hover, &:focus {
    color: var(--cl-grey);
  }
  &:focus {
    outline: max(1px, 0.1em) dashed currentColor;
    // outline-offset: 0.25em;
  }
  
  &:focus:not(:focus-visible) {
    outline: none;
    border: 2px dashed currentColor;
  }
  
  &:focus-visible {
    outline: max(1px, 0.1em) dashed currentColor;
    // outline-offset: 0.25em;
  }
}


.btn {
  display: flex;
  align-items: center;
  font-family: $font-main;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 16px;
  transition: all 0.3s ease;
}

.btn:hover {
  transform: scale(1.05);
}

.btn-icon {
  position: relative;
  right: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background: rgba(0,0,0,0.4);
}
.btn-icon.md {
  padding: 12px;
}

.btn-icon svg {
  font-size: 22px;
}

.btn-icon.btn-icon-right {
  right: -12px;
}

.btn-icon.btn-icon-left {
  left: -12px;
}

.btn-with-icon {
  padding-top: 0;
  padding-bottom: 0;
}

.responsive-button {
  @include mq($bp-xl, 'max') {
    width: 75%;
    margin: 0 auto;
  }
}

.floating-contact-button {
  z-index: 2;
  isolation: isolate;
  bottom: 30px;
  right: 20px;
  border-radius: 25px;
}