@keyframes loader {
  0% {
    opacity: .8;
  }
  30% {
      opacity: .4;
  }
  50% {
      opacity: .6;
  }
  100% {
      opacity: .8;
  }
}

@keyframes tickerAnim {
  0% { transform: translateX(0) }
  100% { transform: translateX(-2770px); }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.1);
    opacity: 1;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes lds-ellipsis1 {
	0% {
			transform: scale(0);
	}
	100% {
			transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
			transform: scale(1);
	}
	100% {
			transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
			transform: translate(0, 0);
	}
	100% {
			transform: translate(19px, 0);
	}
}

@keyframes turnPage {
	from {
		transform: rotateY(-50deg);
	}
	to {
		transform: rotateY(-130deg);
	}
}

@keyframes fadeInLeft {
  0% {
     opacity: 0;
     transform: translateX(-80%);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
     opacity: 0;
     transform: translateX(80%);
  }
  100% {
     opacity: 1;
     transform: translateX(0);
  }
}


@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}


@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}
