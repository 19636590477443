@use '../abstracts' as *;


/* Admissions Landing Styles
**************************************************************************************************************/

.admissions-landing-intro-section {
  padding: 40px 0px 120px;
  position: relative;
  background-color: var(--cl-primary);
  background-image: url('https://ik.imagekit.io/yhoez8no8al/banner/hero-bg-desktop.png?tr=h-625,f-auto');
  background-size: cover;
  background-position: 50% 100%;
  max-height: 625px;
  @include mq($bp-md-2, 'max'){
    background-image: url('https://ik.imagekit.io/yhoez8no8al/banner/hero-bg-tablet.png?tr=w-768,f-auto');
    max-height: unset;
  }
}

.ticker-container {
  width: 100%;
  overflow: hidden;
  @include mq($bp-md-2, 'max') {
    width: 100vw;
    margin-left: -15px;
  }
}

.ticker {
  animation: tickerAnim 100s linear infinite;
}

.ticker img:not(.dn-tick) {
  height: 40px;
  width: auto;
  margin: 0 auto;
  filter: grayscale(1);
}

.univeristy-mention {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  text-align: center;
}


.service-description {
  margin-top: -120px;
}

.service-description-inner {
  padding: 70px 0 35px;
  @include mq($bp-xl) {
    padding: 50px 0 35px;
    min-height: 250px;
  }
}

.admissions-landing-timeline:after {
  display: none;
  @include mq($bp-xxl) {
    width: 798px;
    left: 160px;
    bottom: calc(1.5rem + 6px - 1px);
  }
}

.admissions-inner-nav.why-collegify:before {
  height: calc(100% - 55px);
  @include mq($bp-md-2) {
    height: unset;
  }
}

/* Admissions Common Styles
**************************************************************************************************************/

.admissions-intro-section {
  background: var(--cl-primary-light);
  padding: 30px 0px 65px;
}

.admissions-nav {
  @include flex-center;
  flex-wrap: wrap;
}

.admissions-nav-item {
  padding: 8px 16px;
  border: 2px solid var(--cl-primary);
  background: var(--cl-background);
  border-radius: 3rem;
  text-transform: uppercase;
  margin: 10px;
}

.admissions-nav-item:hover,
.admissions-nav-item.active {
  background: var(--cl-primary);
}

.admissions-nav-item.active {
  pointer-events: none;
}

.admissions-nav-item h6 {
  margin-bottom: 0;
}

.university-logo {
  width: 140px;
  height: auto;
  /* filter: grayscale(1); */
  @include mq($bp-md-2, 'max') {
    width: 100px;
  }
}

.testimonial-inner {
  opacity: 1;
  transition: all 0.3s ease;
}

.testimonial-inner.fade-out {
  opacity: 0;
}

.admissions-inner-nav {
  position: sticky;
  top: 120px;
  &::before {
    z-index: -2;
    content: '';
    display: block;
    width: 2px;
    height: calc(100% - 89px);
    background: var(--cl-primary);
    position: absolute;
    left: 5px;
    top: 8px;
  }
}


.admissions-inner-nav-item {
  position: relative;
  margin-left: 40px;
  margin-bottom: 35px;
  transition: all 0.3s ease;
  &::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: calc(50% - 6px);
    left: -40px;
    border: 2px solid var(--cl-primary);
    background: var(--cl-background);
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  &.active {
    &::before {
      background: var(--cl-primary);
      box-shadow: 0px 0px 10px var(--cl-primary);
    }
  }
  &:last-child::after {
    z-index: -1;
    content: '';
    display: block;
    width: 2px;
    height: 40px;
    background: #fff;
    position: absolute;
    left: calc(-40px + 6px - 1px);
    top: calc(6px + 2px);
  }
}

/* .admissions-inner-nav-item:after {
  z-index: -1;
  content: '';
  display: block;
  width: 2px;
  height: 70px;
  background: var(--c-yellow);
  position: absolute;
  left: calc(-40px + 6px - 1px);
  top: calc(6px + 2px);
} */



.college-logos-cta-card {
  margin-top: -120px;
}

@media screen and (max-width: 993px) {

  .college-logos-cta-card {
      margin-top: -90px;
  }

}

@media screen and (max-width: 767px) {

  .admissions-inner-nav {
      display: none;
  }
  
  .college-logos-cta-card {
      margin-top: -55px;
  }

}

// What we Offer //

.styled-heading {
  position: relative;
  &::before, 
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 48px;
    height: 6px;
    top: calc(50% - 3px);
    background: var(--cl-secondary-light);
    border-radius: 50%;
  }
  &::before {
    left: -72px;
  }
  
  &::after {
    right: -72px;
  }
}

.yellow-circle {
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid var(--cl-primary);
  background: var(--cl-background);
  border-radius: 50%;
  transition: all 0.3s ease;
  margin: 0 auto;
}

.yellow-circle.active {
  background: var(--cl-primary);
  box-shadow: 0px 0px 10px var(--cl-primary);
}

.horizontal-timeline-container {
  position: relative;
}

.horizontal-timeline-container:after {
  z-index: -1;
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  background: var(--cl-primary);
}


.light-blue-icon {
  position: relative;
  width: 96px;
  height: 96px;
  background: var(--cl-secondary-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  &::after {
    content: '';
    display: block;
    background: var(--cl-primary);
    width: 0;
    height: 4px;
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translateX(-50%);
    border-radius: 0.5rem;
    transition: all 0.3s ease;
  }
  svg {
    font-size: $txt-42;
    color: var(--cl-secondary);
    transition: 0.3s ease;
  }
  &.active, &:hover, &:focus {
    background: var(--cl-primary-light);
    border: 2px solid var(--cl-primary);
    transform: scale(1.05);
    &::after {
      width: 30px;
    }
    svg {
      color: var(--cl-primary);
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 2px .25rem var(--cl-primary);
  }
}

/* Disable hover state on mobile */
@media screen and (max-width: 768px) {

  .light-blue-icon:hover {
      background: var(--cl-primary-light);
      border: 2px solid var(--cl-primary);
      transform: scale(1);
  }

  .light-blue-icon:hover:after {
      width: 0;
  }

  .light-blue-icon:hover svg {
      color: var(--cl-primary);
  }

}