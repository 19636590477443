@use '../abstracts' as *;

#notfound {
  flex: 1;
  align-self: center;
  justify-self: center;
  @include flex-center;
}

.notfound {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
}

.image_wrap img{
  height: 45vh;
  width: auto;
  margin: 0 auto 2rem;
}

.notfound h2 {
  font-family: inherit;
  color: hsl(0, 0%, 16%);
  font-size: $txt-32;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 0;
}

.notfound p {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: var(--cl-text-dark);
  max-width: 90%;
  margin: 0 auto 2.2em;
}

.notfound a {
  font-family: inherit;
  font-size: $txt-14;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  background: var(--cl-secondary);
  display: inline-block;
  padding: .85rem 2rem;
  border-radius: 2em;
  color: var(--cl-background);
  font-weight: 700;
  will-change: transform;
  box-shadow: 0px 4px 15px -5px $cblack-trans-4;
  transition: background-color .2s ease-in, transform .4s cubic-bezier(0.19, 1, 0.22, 1);
  transform: scale(1);
  &:hover ,&:focus {
    color: var(--cl-background);
    background-color: var(--cl-secondary-dark);
    transform: scale(1.02);
  }
}