@use '../abstracts' as *;

.admission-card {
  --box-shadow-input: 0 0 0 0.25rem hsla(49, 100%, 50%, .25);

  background-color: var(--cl-primary-light);
  border-radius: rem(10);
  margin-top: 2em;
  z-index: 1;
  isolation: isolate;
  @include mq($bp-md-2) {
    margin-top: 4em;
  }
  .form-control , .form-select {
    border: 1px solid var(--cl-primary);
    &::placeholder {
      font-size: $txt-14;
      color: var(--cl-grey);
    }
    &:focus {
      border-color: var(--cl-primary);
      box-shadow: var(--box-shadow-input);
    }
  }
}

.padding-top-80 {
  padding-top: 80px !important;
  @include mq($bp-sm-2, 'max') {
    padding-top: 40px !important;
  }
}

.educator-card {
  border: 2px solid var(--cl-primary);
  .card-body {
    padding: 1.5em;
  }
  .card-header {
    padding: .75rem 1.25rem;
    background-color: var(--cl-primary);
  }
  &.--secondary {
    border-color: var(--cl-secondary);
    .card-header {
      padding: .75rem 1.25rem;;
      background-color: var(--cl-secondary);
    }
  }
}
.educators-illustration-1 {
  z-index: -1;
  transform: translate(-90px, -120px);
}

.educators-illustration-2 {
  z-index: -1;
  transform: translateX(100px);
}

/* .educators-course-plan-card {
  transform: translateY(-30px);
} */

@media screen and (max-width: 769px) {
  .educators-illustration-1,
  .educators-illustration-2,
  .educators-course-plan-card {
    transform: translate(0);
  }

  .educators-illustration-1,
  .educators-illustration-2 {
    transform: scale(0.8);
  }
}


ul.heading-points.--onBlue li {
  color: var(--cl-background);
}
ul.heading-points {
  list-style: none;
  &.sm {
    li {
      font-size: 18px;
      line-height: 24px;
      color: var(--cl-text-dark);
    }
  } 
  li {
    padding-left: 44px;
    margin: 25px 10px;
    position: relative;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    text-align: left;
    &::before {
      content: '';
      @include bg-check-svg(var(--cl-text-dark));
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 90% 80%;
      padding: 4px 6px;
      color: var(--cl-primary);
      position: absolute;
      left: 0;
      top: -2px;
      width: 36px;
      height: 36px;
      border-radius: 40px;
      background-color: var(--cl-background);
    }
  }
  &.--onBlue li::before {
    background-color: var(--cl-primary);
    color: var(--cl-text-dark);
    padding: 6px;
  }
}
