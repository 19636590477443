@use '../abstracts' as *;

.ak-nav-bar {
  min-height: 100px;
  a {
      color: inherit;
  }
  @include mq($bp-sm-3, 'max') {
    min-height: 70px;
  }
}
.ticker .top-student-card{
    position: relative;
    width: 100%;
    min-width: 288px;
    min-height: 400px;
    border-radius: 8px;
    height: 400px;
}
.ticker .ak-img{
    height: 100% !important;
    filter: none !important;
    padding: 8px !important;
    border-radius: 1rem !important;
    max-height: 408px !important;
}
.webiner-section {
    margin: 4rem auto;
    display: grid;
    place-content: center;
}
  .akbtn {
    width: auto;
    padding: .8rem 2.5rem;
    font-size: 18px;
    line-height: 1;
    border-radius: 0;
    background-color: var(--cl-primary);
    color: var(--cl-text-dark);
    border: none;
    outline: none;
    font-weight: 700;
    transform: translateY(0);
    transition: transform .2s ease-in;
}
.akbtn:focus, .akbtn:hover {
    outline: none;
    transform: translateY(3px);
}
.akbtn.big {
    padding: 1.2rem 3rem;
    max-width: 95%;
    margin: 0 auto;
}
.career-mapping-section {
    background-color: #ececec;
    margin-block-start: 2rem;
    padding: 4em;
    display: grid;
    justify-items: center;
    align-content: center;
}
.career-mapping-cta {
    width: 95%;
    max-width: 575px;
    text-align: center;
}

.ak-hero-bg {
    padding: 2.3rem 0px;
    position: relative;
    background-color: var(--cl-primary);
    background-image: url('https://ik.imagekit.io/yhoez8no8al/admission/ak-banner.png?tr=h-520,f-auto');
    background-size: cover;
    background-position: 50% 100%;
    max-height: 625px;
    @include mq($bp-xl-2, 'max') {
        background-image: url('https://ik.imagekit.io/yhoez8no8al/admission/ak-banner.png?tr=w-1024,f-auto');
        max-height: unset;
    }
    @include mq($bp-md-2, 'max') {
        background-image: url('https://ik.imagekit.io/yhoez8no8al/admission/ak-banner.png?tr=w-768,f-auto');
    }
}

@media screen and (max-width: 1024px) {
    .career-mapping-section {
        padding: 4em 0;
    }

}

.ak-contact-card {
    border: 4px solid var(--cl-primary);
    background-color: var(--cl-background);
    width: 100%;
    max-width: 375px;
    padding: 1.8rem 2rem;
}

.certificate-section {
    background-color: hsl(210, 16%, 93%);
    padding: 5em 0;
}

.ak-img img{
    width: 130px;
    height: 130px;
    border-radius: 72px;
    background-color: hsl(220, 7%, 91%);
    border: 2px solid var(--cl-secondary);
}

ul.blue-dots {
    list-style: initial;
    padding-left: 1rem;
}
ul.blue-dots > li {
    line-height: 1.5;
    padding-left: .85rem;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 8px;
}
ul.blue-dots > li::marker {
    content: "\2022";
    color: var(--cl-secondary);
    font-size: 1.8rem;
    line-height: 1;
}

.text-section {
    padding: 5em 0;
    width: min(572px, 100%);
    margin: 0 auto;
}

.question-wrapper {
    width: 87%;
    margin: 0 auto;
}
.question-wrapper.full {
    width: 100%;
}

.top-admission h3 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 3rem;
}

.media-coverage {
    margin: 5em 0;
}

.media-article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, .8fr));
    gap: 2ch;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    @include mq($bp-lg-2, 'max') {
        grid-template-columns: repeat(2, 1fr);
        &>div:nth-child(3) {
            grid-column: 1 / span 2;
            img {
                width: 320px;
                height: auto;
                margin-inline: auto;
            }
        }
    }
    @include mq($bp-sm-3, 'max') {
        grid-template-columns: repeat(auto-fit, minmax(200px, .8fr));
        &>div:nth-child(3) {
            grid-column: auto;
            img {width: 100%;}
        }
    }

}
.media-article-grid > div {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.media-article-grid > div > img{
    width: 100%;
    transform: scale(1);
    transition: 0.5s all ease-in-out;
}
.media-article-grid > div:hover > img{
    overflow: hidden;
    transform: scale(1.4);
}

@media only screen and (max-width: 768px) {
    .question-wrapper.full {
        width: 90%;
    }
    .question-wrapper h3 {
        font-size: 1.3rem;
    }
    .cert-img-bg {
        width: 100%;
        min-height: 390px;
    }
}
.ak-heading h1{font-weight: 600;}
.ak-testimonial--image {
    border: 3px solid var(--cl-primary);
    border-radius: 50%;
    object-fit: cover;
    padding: 5px;
}


/* New Page AK Page */
.admission-card-reel {
    margin: 3em 0 4.5em;
}
.top-admission--card {
    margin: 80px 12px 0;
    position: relative;
    width: 100%;
    min-width: 290px;
    min-height: 200px;
    height: 375px;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 $cblack-trans-2;
    border: 2px solid var(--cl-primary);
    &.h-auto {
        height: auto;
    }
    .card-body {
        padding: 3.5em 1.2em 1.2em;
    }
    @include mq($bp-md-2, 'max') {
        height: 350px;
    }
    @include mq($bp-sm-3, 'max') {
        height: 500px;
    }
}


.ak-numbers-grid {
    display: grid;
    padding: 1rem 3rem 1rem 0;
    grid-template-columns: repeat(2, minmax(220px, 1fr));
    gap: 2rem;
    @include mq($bp-md-2, 'max') {
        padding: 1rem;
    }
    @include mq($bp-sm-3, 'max') {
        grid-template-columns: 1fr;
        padding: 1rem;
    }
}

.numbers-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(255px, 1fr));
    gap: 2rem;
    @include mq($bp-md-2, 'max') {
        padding: 1rem;
    }
    @include mq($bp-sm-3, 'max') {
        grid-template-columns: 1fr;
        padding: 1rem;
    }
}
.ak-card {
    border-radius: 8px;
    background-color: var(--cl-background);
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.12);
    width: 100%;
    padding: 39px 26px 34px 32px;
    min-height: 160px;
    transform: scale(1);
    transition: transform .4s ease-in;
    &:hover, &:focus {
        transform: scale(1.04);
    }
    h3 {
       font-size: $txt-42;
       font-weight: 700;
       line-height: 1.1;
    }
}
