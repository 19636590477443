@use '../abstracts' as *;

@use '~react-toastify/scss/main.scss';

.Toastify__toast {
    border-radius: rem(8);
    padding: rem(10);
    box-shadow: 1px 1px 8px -1px $cblack-trans-2, -1px -2px 8px 0 $cblack-trans-2;

    &--success {
        background-color: var(--cl-success-light);
        color: var(--cl-success);
        border: 1px solid var(--cl-success);
        position: relative;
    }
    &--info {
        background-color: var(--cl-secondary-light);
        color: var(--cl-secondary);
        border: 1px solid var(--cl-secondary);
        position: relative;
    }
    &--warning {
        background-color: var(--cl-warning-light);
        color: var(--cl-warning);
        border: 1px solid var(--cl-warning);
        position: relative;
    }
    &--error {
        background-color: var(--cl-danger-light);
        color: var(--cl-danger);
        border: 1px solid var(--cl-danger);
        position: relative;
    }
    &-container {
        width: 100%;
        @include mq($bp-md-2) {
            width: 360px;
        }
    }
    &-body {
        display: flex;
        gap: 1.2ch;
        padding: 6px 10px;
    }
    &::before {
        content: "";
        width: 4px;
        flex-shrink: 0;
        border-radius: 5px;
        bottom: 0;
        top: 0;
        left: 0;
        background-color: currentColor;
    }
}
.toast__icon {
    flex-shrink: 0;
}
.Toastify__close-button {
    color: var(--cl-grey-dark);
    align-self: center;
    flex-shrink: 0;
    > svg {
        width: 22px;
        height: 20px;
    }
}
.custom__toast {
    padding: 0;
    font-size: $txt-16;
    line-height: 1.5;
    font-weight: 600;
    letter-spacing: 0.2px;
}

// Toaster Animation

.slide-in-blurred-right {
    animation: slide-in-blurred-right 700ms cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
.slide-out-blurred-right {
    animation: slide-in-blurred-right 700ms cubic-bezier(0.55, 0.085, 0.68, 0.53) reverse both;
}
.slide-in-blurred-left {
    animation: slide-in-blurred-left 0.7s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@keyframes slide-in-blurred-right {
    0% {
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 0% 50%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
}
@keyframes slide-in-blurred-left {
    0% {
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform-origin: 100% 50%;
        filter: blur(40px);
        opacity: 0;
    }
    100% {
        transform: translateX(0) scaleY(1) scaleX(1);
        transform-origin: 50% 50%;
        filter: blur(0);
        opacity: 1;
    }
  }
