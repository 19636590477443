@use './vars' as *;

@mixin clearfix {
  &::after {
      content: "";
      display: table;
      clear: both;
  }
}
@mixin absTopLeft {
  position: absolute;
  top: 0;
  left: 0;
}
@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@mixin absVlCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absHrCenter {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin defaultTransition {
  transition: all .3s ease;
}

@mixin  transition-slow {
  transition: all .5s ease-out;
}

@mixin flex-row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
@mixin flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin card-shadow {
  box-shadow: 0px 4px 42px $cblack-trans-2;
}

@mixin bg-check-svg($color) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$color}' width='16px' height='16px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
}

// MEDIA QUERY DISTRIBUTE

$bp-sm : 24em; // basically, 384px
$bp-sm-2 : 29.75em; // 476px
$bp-sm-3 : 39.8em; // 636.8px
$bp-md : 46.8em; // 748px
$bp-md-2 : 48em; //768px
$bp-lg : 50em; // 800px
$bp-lg-2 : 54.5em; //872px
$bp-xl : 60em; // 960px
$bp-xl-2 : 67em; //1072px
$bp-xxl : 75em; //1200px;
$bp-xxl-2: 85.5em; //1368px
$bp-max: 90em; //1440px
$bp-max-2: 104em; //1660px

@mixin mq($bp, $type:'min') {
  @if $type == 'min' {
    @media only screen and (min-width: $bp) { @content };
  }
  @if $type == 'max' {
    @media only screen and (max-width: $bp) { @content };
  }
}
