@use '../abstracts' as *;

.video-wrapper {
  background: var(--cl-text-dark);
  box-shadow: 0px 4px 12px $cblack-trans-4;
  border-radius: rem(12);
  height: 302px;
  width: 535px;
  iframe {
    width: 100%;
    height: 100%;
    border-radius: rem(12);
  }
  @include mq($bp-md-2, 'max') {
    max-height: 298px;
    max-width: 92vw;
  }
  @include mq($bp-sm-3, 'max') {
    max-height: 214px;
  }
}

video#highlight-video {
  border-radius: rem(12);
  object-fit: contain;
}

.ai-feature__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem 3.5rem;
  margin-bottom: 3rem;
  @include mq($bp-md-2, 'max') {
    grid-template-columns: 1fr;
  }
}

.landing-mapping-image {
  position: absolute;
  min-width: 700px;
  top: -60px;
  right: -90px;
}
.border-warning {
  border: 1px solid #FFD33E !important;
}
.authorImage img {
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
}
.coupon-dismiss {
  position: absolute;
  right: 6rem;
  top: 21%;                
}
.landing-top-section {
  padding: 5rem 0 3rem;
  @include mq($bp-md-2, 'max') {
    background: var(--cl-primary-light);
    margin-top: -30px;
  }
}
@media screen and (max-width: 768px) {
  .landing-mapping-image {
      position: relative;
      top: 0px;
      min-width: 100%;
      max-width: 100%;
      right: 0px;
  }
}

