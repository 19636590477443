@use '../abstracts' as *;

/**************** Footer Designs ************************
*********************************************************/

#footer {
  background-color: var(--cl-footer);
  padding: rem(30);
  font-size: 1rem;
  margin-block-start: auto;
  color: var(--cl-background);
  h5 {
    font-size: $txt-16;
    line-height: inherit;
    margin-bottom: .8rem;
    font-weight: 400;
    color: var(--cl-background);
    @include mq($bp-sm, 'max') {
      font-size: $txt-14;
    }
  }
  .footer-links-social {
    a {
      color: inherit;
    }
  }
}

.footer-grid {
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr;
  gap: .85rem;
}

.footer-grid.AK {
  max-width: 1140px;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  place-items: center;
  justify-items: center;
}

.footer-branding .logo {
  width: 120px;
  height: auto;
}

@include mq($bp-lg-2, 'max') {

  .footer-grid {
      grid-template-columns: 1fr 1fr 1fr;
  }

  .footer-links-1,
  .footer-links-2,
  .footer-links-3 {
      text-align: center;
  }

  .footer-links-social {
      grid-column: 1/4;
      text-align: center;
      margin-top: 10px;
  }

  .footer-grid.AK {
      place-items: start;
  }
  .footer-grid.AK .footer-links-3 {
      grid-column: 1/-1;
  }

  .footer-branding {
      display: none;
  }

}

@include mq($bp-sm-2, 'max') {
  .footer-grid {
    grid-template-columns: 1fr 1fr;
    .footer-links-social {
      grid-column: 2 / 3;
      text-align: left;
    }
    .footer-links-1,
    .footer-links-2,
    .footer-links-3 {
        text-align: left;
    }
    .footer-links-3,
    .footer-links-social {
        margin-top: 10px;
    }
  }

  .footer-grid.AK .footer-links-3 {
      grid-column: 1/-1;
  }
  
}
