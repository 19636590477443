@use '../abstracts' as *;
/* Carousels */

.testimonial-section {
  width: min(980px, 90%);
  margin: 5em auto;
}

.testimonial-box {
  background-color: transparent;
  margin: 0 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.6ch;
}

.testimonial-box img {
  width: 200px;
}
.testimonial-box p {
  font-size: 14px;
  line-height: 25px;
  max-width: 60ch;

}
.testimonial-box h5 {
  color: var(--cl-secondary);
  font-weight: 700;
  font-size: 18px;
}
.testimonial-box h6 {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  /* .testimonial-box {
    flex-direction: column;
    border: 3px solid var(--c-yellow);
    box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.12);
  } */

  .testimonial-box p {
    font-size: 15px;
    line-height: 24px;
  }
}

.carousel {
	position: relative;
}

.carousel._initialized {}

.carousel__track {
	display: flex;
	overflow: hidden;
	overflow-behavior: contain;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	-ms-overflow-style: none; /* IE */
	overflow-x: auto;
	overscroll-behavior-x: none; /* contain */
	scroll-behavior: smooth;
	-ms-scroll-chaining: none;
	/*-ms-scroll-snap-type: x mandatory;*/
	/*-webkit-scroll-snap-type: x mandatory;*/
	scroll-snap-type: x proximity;
	scrollbar-width: none; /* FF */
}

.carousel__track::-webkit-scrollbar {
	display: none;
}

.scroll__track._grabbing,
.carousel__track._grabbing {
	cursor: -moz-grab;
	cursor: -webkit-grab;
	cursor: grab;
	user-select: none;
}

.scroll__track._grabbing::before,
.carousel__track._grabbing::before {
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 999;
}

.carousel._mandatory .carousel__track {
	scroll-snap-type: x mandatory;
}

/* Buttons */

.carousel::before,
.carousel::after {
	background: transparent;
	bottom: 0;
	content: "";
	display: block;
	height: auto;
	opacity: 0;
	position: absolute;
	top: 0;
	transition: ease 0.2s;
	transition-property: visibility, opacity;
	visibility: hidden;
	width: 20px;
	z-index: 1;
}

.carousel::before {
	/* background: radial-gradient(ellipse at -30% 50%, rgba(0, 0, 0, 0.3), rgba(97, 97, 97, 0) 72%); */
	left: 0;
}

.carousel::after {
	/* background: radial-gradient(ellipse at 70% 50%, rgba(0, 0, 0, 0.3), rgba(97, 97, 97, 0) 72%); */
	right: 0;
}

.carousel._initialized._next-visible::after,
.carousel._initialized._prev-visible::before {
	opacity: 1;
	visibility: visible;
}

.carousel__ctrl {
	background-color: #fff;
	border: none;
	border-radius: 100%;
	box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.2);
	height: 40px;
	margin-top: -20px;
	position: absolute;
	text-align: center;
	top: 50%;
	user-select: none;
	width: 40px;
  	color: var(--primary);
	z-index: 2;
}

.carousel__ctrl.bg-trans {
	background-color: transparent;
	box-shadow: none;
}

.carousel__ctrl[disabled]:not(.bg-trans) {
	color: grey;
	background-color: #dadada;
}

.carousel__ctrl._prev {
	left: -20px;
}

.carousel__ctrl._next {
	right: -20px;
}

/* pagination */

.pagination {
	margin: 5px;
	text-align: center;
  display: flex;
  justify-content: center;
}

.page {
	display: inline-block;
	background-color: lightgrey;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	height: 6px;
	margin: 3px;
	padding: 0;
	transition: background-color ease 0.4s;
	width: 6px;
}

.page._current {
	background-color: var(--cl-secondary);
}

/* content and variations */

.box {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 400px;
	scroll-snap-align: center;
}

.box._no-snap {
	scroll-snap-align: none;
}

.box._single {
	max-width: 100%;
	min-width: 100%;
	background-image: url('/images/AK/quote.png');
	background-size: 20%;
	background-position: bottom 5% right 5%;
	background-repeat: no-repeat;
}
.box._single.bg-trans {
	background-image: none;
	background-color: transparent;
}

@media screen and (max-width: 768px) {
  .box._single {
    background-size: 30%;
  }
  
}

.box._third {
	max-width: 33.333333%;
	min-width: 33.333333%;
}

.box._fours {
	max-width: 25%;
	min-width: 25%;
}

.box._half {
	max-width: 50%;
	min-width: 50%;
	scroll-snap-align: start;
}

.box._half-next { /* classical visual hint about upon scroll */
	max-width: 28.57%;
	min-width: 28.57%;
}
@media screen and (max-width: 768px) {
	.box {
		min-height: auto;
	}
	.box._half {
		max-width: 100%;
		min-width: 100%;
	}
}
@media only screen and (max-width: 1196px) {
	.box._half {
		max-width: 100%;
		min-width: 100%;
	}
}
/* Vertical carousel */

.scroll {
	padding: 1px 10px;
	position: relative;
}

.scroll::before,
.scroll::after {
	background-color: #666;
	content: "";
	height: 1px;
	left: 50%;
	opacity: 0;
	position: absolute;
	transition: ease 0.3s;
	transition-property: width, left, opacity;
	width: 0;
	z-index: 1;
}

.scroll._initialized._prev-visible::before,
.scroll._initialized._next-visible::after {
	left: 0;
	opacity: 1;
	width: 100%;
}

/* This is the heart of the carousel */
.scroll__track {
	display: flex;
	flex-direction: column;
	height: 400px;
	overflow: hidden;
	overflow-behavior: contain;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	-ms-overflow-style: none; /* IE */
	overflow-y: auto;
	overscroll-behavior-y: contain;
	scroll-behavior: smooth;
	-ms-scroll-chaining: none;
	scroll-snap-type: y proximity;
	scrollbar-width: none; /* FF */
}

.scroll__track::-webkit-scrollbar {
	display: none;
}

.thumb {
	background: #eee;
	cursor: pointer;
	display: block;
	height: 40px;
	line-height: 40px;
	margin-bottom: 5px;
	position: relative;
	scroll-snap-align: center;
	text-align: center;
	width: 40px;
}

.thumb::after {
	bottom: 0;
	box-shadow: inset 0 0 0 0 lightblue;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: box-shadow ease 0.3s;
}

.thumb._current::after {
	box-shadow: inset 0 0 0 3px lightblue;
}

/* Gallery */

.gallery {
	display: flex;
}

.gallery .scroll {
	flex-basis: 40px;
	margin-right: 20px;
}

.gallery .carousel {
	max-width: 400px;
	width: 100%;
}
